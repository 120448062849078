<template>
  <div v-if="button">
    <div
      class="fixed top-0 left-0 w-screen wb-bg-black overflow-hidden z-60 transition"
      :class="{
        'opacity-0': !buttonToggled,
        'opacity-50': buttonToggled,
        'h-0': !buttonToggled,
        'h-screen': buttonToggled,
      }"
    ></div>
    <div
      class="fixed top-0 left-0 w-full grid lg:grid-cols-3 mt-10 z-60 transition transform"
      :class="{
        'opacity-0': !buttonToggled,
        'h-0': !buttonToggled,
        '-translate-y-96': !buttonToggled,
        'h-auto': buttonToggled,
      }"
    >
      <div
        class="lg:col-start-2 flex items-start justify-center flex-col gap-6 bg-white rounded-lg py-6 px-6"
      >
        <h2 class="text-2xl">
          {{ button.text }}
        </h2>

        <div
          class="mx-auto flex items-center justify-center flex-col gap-2"
          v-if="button.actionFuncs && button.actionFuncs.buttons"
        >
          <Button
            v-for="(qbutton, qindex) in button.actionFuncs.buttons"
            :key="'qindex' + qindex"
            @wasClicked="triggerQButton(qbutton)"
            colors="text-white wb-bg-green"
          >
            {{ qbutton.text }}
          </Button>
        </div>

        <div v-if="button.actionFuncs.sql" class="w-full">
          <div
            class="mx-auto flex items-center justify-center flex-col gap-2 w-full"
            v-for="(sqlbtn, indexsql) in button.actionFuncs.sql"
            :key="'sqlbtn' + indexsql"
          >
            <input
              v-for="(qbutton, qindex) of calcLenInputSQL(sqlbtn)"
              :key="'q_sql_index' + qindex"
              :placeholder="sqlbtn.placeholder"
              v-model="sqlVars[qindex]"
              class="border-0 placeholder-blueGray-300 rounded text-sm px-3 py-3 focus:outline-none focus:ring w-full ease-linear transition-all duration-150 bg-white shadow text-blueGray-600"
            />
            <Button class="w-full" @wasClicked="sqlAction(button.id)">
              Do
            </Button>
          </div>
        </div>

        <div class="grid lg:grid-cols-2 w-full gap-8 mt-6">
          <Button @wasClicked="disableButton()" colors="text-white wb-bg-red">
            Cancel
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Inter/Button"
import router from "@/router"
import nvgpost from "@/nvgpost"

export default {
  name: "QButton",
  components: { Button },
  computed: {
    currentTable: function () {
      return this.$store.getters.currentTable
        ? this.$store.getters.currentTable
        : this.$store.getters.databases[0]
    },
    button: function () {
      return this.$store.state.buttonWindow
    },
    buttonToggled: function () {
      return this.$store.state.buttonWindow.toggled
    },
  },
  data() {
    return {
      sqlVars: [],
    }
  },
  methods: {
    disableButton: function () {
      this.$store.commit("toggleButton")
    },
    triggerQButton(qbutton) {
      if (qbutton.action === "new") {
        router.push(
          "/tables/" +
            this.currentTable.db +
            "/" +
            qbutton[qbutton.action] +
            "/new"
        )
      }
      this.disableButton()
    },
    calcLenInputSQL(sqlbtn) {
      if (sqlbtn.input) return 1
      else return sqlbtn.code.split("?").length - 1
    },
    sqlAction(qbuttonId) {
      console.log(qbuttonId)
      nvgpost(
        this.$apiEndpoint + "/v1/qbutton",
        "POST",
        { type: "sql", vars: this.sqlVars, qbuttonId: qbuttonId },
        {},
        (res) => {
          if (res.data) {
            this.$store.commit("toggleButton")
            this.$root.$emit("alert", "Action did!", 1)
          } else {
            this.$root.$emit("alert", "Action Error", 2)
          }
        },
        true
      )
    },
  },
}
</script>
