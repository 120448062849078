<template>
  <nav
    class="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6"
  >
    <div
      class="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto"
    >
      <!-- Toggler -->
      <button
        class="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
        type="button"
        v-on:click="toggleCollapseShow('bg-white m-2 py-3 px-6')"
      >
        <MenuIcon />
      </button>
      <!-- Brand -->
      <router-link
        class="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
        to="/dashboard"
      >
        <img
          v-if="weboaLogo"
          :src="weboaLogo"
          alt="Logo"
          class="max-h-[64px] object-cover"
        />
        <span v-else>Weboa Admin</span>
      </router-link>
      <!-- User -->
      <ul class="md:hidden items-center flex flex-wrap list-none">
        <li class="inline-block relative">
          <notification-dropdown />
        </li>
        <li class="inline-block relative">
          <user-dropdown />
        </li>
      </ul>
      <!-- Collapse -->
      <div
        class="md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded"
        v-bind:class="collapseShow"
      >
        <!-- Collapse header -->
        <div
          class="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200"
        >
          <div class="flex flex-wrap">
            <div class="w-6/12">
              <router-link
                class="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
                to="/dashboard"
              >
                <img
                  v-if="weboaLogo"
                  :src="weboaLogo"
                  alt="Logo"
                  class="max-h-[64px] object-cover"
                />
                <span v-else>Weboa Admin</span>
              </router-link>
            </div>
            <div class="w-6/12 flex justify-end">
              <button
                type="button"
                class="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                v-on:click="toggleCollapseShow('hidden')"
              >
                <XIcon />
              </button>
            </div>
          </div>
        </div>

        <!-- Form -->
        <form class="mt-6 mb-4 hidden">
          <div class="mb-3 pt-0">
            <input
              type="text"
              placeholder="Search"
              class="border-0 px-3 py-2 h-12 border border-solid border-blueGray-500 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-base leading-snug shadow-none outline-none focus:outline-none w-full font-normal"
            />
          </div>
        </form>

        <div>
          <!-- Divider -->
          <hr class="my-4 md:min-w-full" />
          <!-- Heading -->
          <h6
            class="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline"
          >
            Main
          </h6>
          <!-- Navigation -->

          <ul class="md:flex-col md:min-w-full flex flex-col list-none">
            <li
              class="items-center"
              v-for="module in modules"
              :key="'module_' + module.url"
            >
              <router-link
                :to="'/' + module.url"
                custom
                v-slot="{ href, navigate, isActive }"
              >
                <a
                  :href="href"
                  @click="navigate"
                  class="text-xs uppercase py-3 font-bold block flex justify-start items-center gap-2"
                  :class="[
                    isActive
                      ? 'text-emerald-500 hover:text-emerald-600'
                      : 'text-blueGray-700 hover:text-blueGray-500',
                  ]"
                >
                  <component
                    :is="module.icon"
                    :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"
                  />
                  {{ module.name }}
                </a>
              </router-link>
            </li>
          </ul>
        </div>

        <QButton v-if="buttons && buttons.length" />

        <!-- Divider -->
        <hr v-if="buttons && buttons.length" class="my-4 md:min-w-full" />

        <div v-if="buttons && buttons.length">
          <ul class="md:flex-col md:min-w-full flex flex-col list-none">
            <li
              class="items-center"
              v-for="button in buttons"
              :key="'button_' + button.name"
            >
              <Button
                v-if="button.type === 'modal'"
                @wasClicked="actionQButton(button)"
                class="flex items-center justify-center gap-2"
              >
                <component :is="button.icon" />
                {{ button.name }}
              </Button>
            </li>
          </ul>
        </div>

        <div
          class="pb-16"
          v-for="(db, db_index) in databases"
          :key="'db' + db_index"
        >
          <!-- Divider -->
          <hr class="my-4 md:min-w-full" />
          <!-- Heading -->
          <h6
            class="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline"
          >
            {{ db }}
          </h6>
          <!-- Navigation -->

          <ul class="md:flex-col md:min-w-full flex flex-col list-none">
            <li
              class="items-center"
              v-for="table in tables[db]"
              :key="'table_' + table.name"
            >
              <router-link
                :to="{
                  name: 'Table',
                  params: { dbName: db, tableName: table.url },
                }"
                custom
                v-slot="{ href, navigate, isActive }"
              >
                <a
                  :href="href"
                  @click="navigate"
                  class="text-xs uppercase py-3 font-bold block flex justify-start items-center gap-2"
                  :class="[
                    isActive
                      ? 'text-emerald-500 hover:text-emerald-600'
                      : 'text-blueGray-700 hover:text-blueGray-500',
                  ]"
                >
                  <DatabaseIcon
                    :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"
                  />
                  {{ table.verbose }}
                </a>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</template>
<style scoped>
.max-h-\[64px\] {
  max-height: 64px;
}
</style>

<script>
import NotificationDropdown from "@/components/Dropdowns/NotificationDropdown.vue"
import UserDropdown from "@/components/Dropdowns/UserDropdown.vue"
import {
  MenuIcon,
  XIcon,
  ChartSquareBarIcon,
  PlusIcon,
  LightningBoltIcon,
  CogIcon,
  ShieldExclamationIcon,
  TranslateIcon,
  DatabaseIcon,
  ViewGridIcon,
  UploadIcon,
} from "@vue-hero-icons/outline"
import nvgpost from "@/nvgpost"
import Button from "@/components/Inter/Button"
import QButton from "@/components/Sidebar/QButton"

export default {
  data() {
    return {
      collapseShow: "hidden",
      weboaLogo: "",
    }
  },
  computed: {
    databases: function () {
      return this.$store.getters.databases
    },
    tables: function () {
      return this.$store.state.tables
    },
    buttons: function () {
      return this.$store.state.buttons
    },
    modules: function () {
      return this.$store.state.modules.filter(
        (element) => element.visible === "1"
      )
    },
  },
  methods: {
    toggleCollapseShow: function (classes) {
      this.collapseShow = classes
    },
    actionQButton(button) {
      this.$store.commit("dataButton", {
        text:
          JSON.parse(button.action) && JSON.parse(button.action).modal_text
            ? JSON.parse(button.action).modal_text
            : "Choose",
        actionFuncs: JSON.parse(button.action),
        id: button.id,
      })
      this.$store.commit("toggleButton")
    },
  },
  components: {
    QButton,
    Button,
    NotificationDropdown,
    UserDropdown,
    PlusIcon,
    MenuIcon,
    XIcon,
    ChartSquareBarIcon,
    CogIcon,
    ShieldExclamationIcon,
    TranslateIcon,
    DatabaseIcon,
    ViewGridIcon,
    UploadIcon,
    LightningBoltIcon,
  },
  created() {
    this.$store.dispatch("getModules", { that: this })
    this.$store.dispatch("getTables", { that: this })
    this.$store.dispatch("getButtons", { that: this })

    nvgpost(
      this.$apiEndpoint + "/v1/weboalogo",
      "GET",
      {},
      {},
      (res) => {
        if (res.data) {
          this.weboaLogo = res.data
        }
      },
      true
    )
  },
}
</script>
