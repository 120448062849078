import Vue from "vue"
import Vuex from "vuex"
import nvgpost from "@/nvgpost";
import router from "@/router";
import getKeyByValue from "@/helpers/getKeyByValue";

Vue.use(Vuex)

Vue.prototype.$apiEndpoint =
    process.env.NODE_ENV === "production"
        ? process.env.VUE_APP_APIENDPOINT
        : "http://localhost:8000/api"

//domains for scontent
Vue.prototype.$scontentPath = process.env.VUE_APP_SCONTENTPATH

export default new Vuex.Store({
    state: {
        user: {},
        wasGotUser: false,
        admins: [],
        images: [],
        currentModule: {},
        dict: {},
        modules: [],
        tables: [],
        buttons: [],
        tablesList: [],
        tableData: {},
        tableData0: {},
        chartsDashboard: {},
        selectors: [],
        selectorsLists: [],
        tableDataPage: false,
        coolDownSaveTable: 0,
        confirmWindow: {
            toggled: false,
            yesFunc: () => {},
            text: ""
        },
        buttonWindow: {
            toggled: false,
            actionFuncs: [],
            id: "",
            text: ""
        }
    },
    getters: {
        isAuthenticated: state => state.user.uid !== undefined,
        wasGotUser: state => !!state.wasGotUser,
        databases: state => Object.keys(state.tables),
        currentTable: function (state) {
            let result = {}
            if (state.tableDataPage) {
                const route = router.currentRoute
                const dbName = route.params.dbName
                const tableName = route.params.tableName

                if (Object.keys(state.tables).length && state.tables[dbName]) {
                    result = state.tables[dbName].filter(function (obj) {
                        return obj.url === tableName
                    })[0]
                }
            }

            return result
        },
    },
    mutations: {
        setUser: function (state, payload) {
            state.user = payload
        },
        gotUser: function (state, payload) {
            state.wasGotUser = payload
        },
        setAdmins: function (state, payload) {
            state.admins = payload
        },
        setDict: function (state, payload) {
            state.dict = payload
        },
        setCurrentModule: function (state, payload) {
            state.currentModule = payload
        },
        setModules: function (state, payload) {
            state.modules = payload
        },
        setTables: function (state, payload) {
            state.tables = payload
        },
        setButtons: function (state, payload) {
            state.buttons = payload
        },
        setSelectors: function (state, payload) {
            state.selectors = payload
        },
        setSelectorsLists: function (state, payload) {
            state.selectorsLists = payload
        },
        toggleTableDataPage: function (state) {
            state.tableDataPage = !state.tableDataPage
        },
        toggleConfirm: function (state) {
            state.confirmWindow.toggled = !state.confirmWindow.toggled
        },
        dataConfirm: function (state, payload) {
            state.confirmWindow.text = payload.text
            state.confirmWindow.yesFunc = payload.yesFunc
        },
        toggleButton: function (state) {
            state.buttonWindow.toggled = !state.buttonWindow.toggled
        },
        dataButton: function (state, payload) {
            state.buttonWindow.text = payload.text
            state.buttonWindow.actionFuncs = payload.actionFuncs
            state.buttonWindow.id = payload.id
        },
        setImages: function (state, payload) {
            state.images = payload
        },
        addCoolDown: function (state) {
            if(state.coolDownSaveTable === 0)
            {
                state.coolDownSaveTable = 1
                setTimeout(function () {
                    state.coolDownSaveTable = 0
                }.bind(this), 5000)
            }
        },
        doMutate: function (state, payload) {
            state[payload[0].key] = payload[0].value
        }
    },
    actions: {
        logout: async function (context, payload) {
            let that = false
            if(payload)
            {
                that = payload.that
                delete payload.that
            }

            await nvgpost(Vue.prototype.$apiEndpoint+"/v1/auth",
                "DELETE",
                {},
                {}, () => {
                    context.commit("setUser", {})
                    router.push({name: "Start"})
                    if(that)
                        that.$root.$emit("alert", "Logout success", 1)
                })
        },
        getUser: async function (context) {
            const timeOut = 50000
            if(!context.getters.wasGotUser)
            {
                await nvgpost(Vue.prototype.$apiEndpoint+"/v1/user",
                    "GET",
                    {},
                    {}, (res) => {
                        context.commit("setUser", {
                            email: res.email,
                            uid: res.email
                        })
                    }, () => {}, true)
                context.commit("gotUser", true)

                setTimeout(function () {
                    context.commit("gotUser", false)
                }, timeOut)
            }
        },
        getJWT: async function (context, payload) {
            const that = payload.that
            delete payload.that

            await nvgpost(Vue.prototype.$apiEndpoint+"/v1/auth",
                "POST",
                {
                    'email': payload.email,
                    'password': payload.password,
                },
                {}, (res) => {
                    if(res.email) {
                        context.commit("setUser", {
                            email: res.email,
                            uid: res.email
                        })
                        router.push({name: "Dashboard"})
                    }
                    else {
                        that.$root.$emit("alert", "Login error!", 2)
                    }
                }, () => {
                    that.$root.$emit("alert", "Login error!", 2)
                })
        },
        getCurrentModule: async function(context, payload) {
            const that = payload.that
            delete payload.that

            await nvgpost(Vue.prototype.$apiEndpoint+"/v1/module",
                "POST",
                payload,
                {}, (res)=>{
                    if(res.data) {
                        context.commit("setCurrentModule", res.data)
                    }
                    else {
                        that.$root.$emit("alert", "Open module error", 2)
                    }
                }, true)
        },
        getAdmins: async function(context, payload) {
            if(!context.state.admins.length) {
                const that = payload.that
                delete payload.that

                await nvgpost(Vue.prototype.$apiEndpoint+"/v1/admins",
                    "GET",
                    {},
                    {}, (res)=>{
                        if(res.data) {
                            context.commit("setAdmins", res.data)
                        }
                        else {
                            that.$root.$emit("alert", "Admins get error", 2)
                        }
                    }, true)
            }
        },
        getDict: async function(context, payload) {
            if(!Object.keys(context.state.dict).length) {
                const that = payload.that
                delete payload.that

                await nvgpost(Vue.prototype.$apiEndpoint+"/v1/dict",
                    "GET",
                    {},
                    {}, (res)=>{
                        if(res.data) {
                            context.commit("setDict", res.data)
                        }
                        else {
                            that.$root.$emit("alert", "Dict get error", 2)
                        }
                    }, true)
            }
        },
        saveDict: async function(context, payload) {
            const that = payload.that
            delete payload.that

            await nvgpost(Vue.prototype.$apiEndpoint+"/v1/dict",
                "PUT",
                payload,
                {}, (res)=>{
                    if(res.data) {
                        context.commit("setDict", res.data)
                    }
                    else {
                        that.$root.$emit("alert", "Dict save error!", 2)
                    }
                }, true)
        },
        getModules: async function(context, payload) {
            if(!Object.keys(context.state.modules).length) {
                const that = payload.that
                delete payload.that

                await nvgpost(Vue.prototype.$apiEndpoint+"/v1/modules",
                    "GET",
                    {},
                    {}, (res)=>{
                        if(res.data) {
                            context.commit("setModules", res.data)
                        }
                        else {
                            that.$root.$emit("alert", "Modules get error", 2)
                        }
                    }, true)
            }
        },
        getTables: async function(context, payload) {
            if(!Object.keys(context.state.tables).length) {
                const that = payload.that
                delete payload.that

                await nvgpost(Vue.prototype.$apiEndpoint+"/v1/tables",
                    "GET",
                    {},
                    {}, (res)=>{
                        if(res.data) {
                            context.commit("setTables", res.data)
                        }
                        else {
                            that.$root.$emit("alert", "Tables get error", 2)
                        }
                    }, true)
            }
        },
        getButtons: async function(context, payload) {
            if(!Object.keys(context.state.buttons).length) {
                const that = payload.that
                delete payload.that

                await nvgpost(Vue.prototype.$apiEndpoint+"/v1/buttons",
                    "GET",
                    {},
                    {}, (res)=>{
                        if(res.data) {
                            context.commit("setButtons", res.data)
                        }
                        else {
                            that.$root.$emit("alert", "Buttons get error", 2)
                        }
                    }, true)
            }
        },
        getTablesAll: async function(context, payload) {
            if(!Object.keys(context.state.tablesList).length) {
                const that = payload.that
                delete payload.that

                await nvgpost(Vue.prototype.$apiEndpoint+"/v1/tables",
                    "POST",
                    {all: true},
                    {}, (res)=>{
                        if(res.data) {
                            context.commit("doMutate", [{
                                key: "tablesList",
                                value: res.data
                            }])
                        }
                        else {
                            that.$root.$emit("alert", "Tables get error", 2)
                        }
                    }, true)
            }
        },
        getTableData: async function(context, payload) {
            const nasil = payload.nasil
            if(nasil) {
                delete payload.nasil
            }

            if((nasil || !context.state.tableData[payload.output] || payload.filter !== undefined)
                && payload.table) {
                const that = payload.that
                delete payload.that

                await nvgpost(Vue.prototype.$apiEndpoint+"/v1/table_data",
                    "POST",
                    payload,
                    {}, async (res)=>{
                        if(res.data) {
                            let var1 = {
                                key: "tableData",
                                value: {}
                            }
                            var1.value[payload.output] = res.data[payload.output]

                            context.commit("doMutate", [var1])

                            if(res.data0) {
                                //data0
                                var1 = {
                                    key: "tableData0",
                                    value: {}
                                }
                                var1.value[payload.output] = res.data0[payload.output]

                                context.commit("doMutate", [var1])
                            }

                            // check for selectors if they exist
                            const indexSelector = context.getters.currentTable.fields
                                .map(object => object.type).indexOf("select")
                            const indexSelector2 = context.getters.currentTable.actionData
                            && context.getters.currentTable.actionData.entity ?
                                context.getters.currentTable.actionData.entity.fields.map(object => object.type).indexOf("select")
                                : -1
                            if(indexSelector !== -1 || indexSelector2 !== -1) {
                                //get all selectors (categories names) from .select key if type is select
                                let categories = context.getters.currentTable.fields.
                                filter(object => object.type === "select").map(object => object.select)

                                if(indexSelector2 !== -1) {
                                    categories = [...categories, ...context.getters.currentTable.actionData.entity.fields.
                                    filter(object => object.type === "select").map(object => object.select)]
                                        //make it unique array
                                        .filter((value, index, self) => {
                                            return self.indexOf(value) === index
                                        })
                                }

                                // get selects options
                                await nvgpost(Vue.prototype.$apiEndpoint+"/v1/selectors",
                                    "POST",
                                    {
                                        categories: categories
                                    },
                                    {}, (res2)=>{
                                        if(res2.data) {
                                            let selectors = {}
                                            res2.data.map(function (obj) {
                                                const names = obj.selector_names.split(",")
                                                const verboses = obj.verbose.split(",")
                                                selectors[obj.category] = []
                                                verboses.map(function (name, index) {
                                                    selectors[obj.category].push({
                                                        name: names[index],
                                                        verbose: verboses[index]
                                                    })
                                                })
                                                return obj
                                            })
                                            context.commit("setSelectors", selectors)
                                        }
                                        else {
                                            that.$root.$emit("alert", "Selectors get error", 2)
                                        }
                                    }, true)
                            }

                            // check for selectors lists if they exist
                            const indexSelectorList = context.getters.currentTable.actionData
                            && context.getters.currentTable.actionData.entity ?
                                context.getters.currentTable.actionData.entity.fields
                                    .map(object => object.type).indexOf("selectlist")
                                : -1
                            if(indexSelectorList !== -1) {
                                let selectorLists = context.getters.currentTable.actionData.entity.fields
                                    .filter(object => object.type === "selectlist")

                                // get options multi tags for select list
                                await nvgpost(Vue.prototype.$apiEndpoint+"/v1/lists",
                                    "POST",
                                    {
                                        fields: selectorLists
                                    },
                                    {}, (res2)=>{
                                        if(res2.data) {
                                            context.commit("setSelectorsLists", res2.data)
                                        }
                                        else {
                                            that.$root.$emit("alert", "Selectors get error", 2)
                                        }
                                    }, true)
                            }
                        }
                        else {
                            that.$root.$emit("alert", "Table get error", 2)
                        }
                    }, true)
            }
        },
        saveTableData: async function(context, payload) {
            const that = payload.that
            delete payload.that

            await nvgpost(Vue.prototype.$apiEndpoint+"/v1/table_data",
                "PUT",
                payload,
                {}, (res)=>{
                    if(res.data) {
                        const currentTableData = context.state.tableData[payload.point.url]
                        const index = getKeyByValue(currentTableData, payload.subject)

                        let fullData = Object.assign({}, context.state.tableData)
                        fullData[payload.point.url][index] =
                            Object.assign(context.state.tableData[payload.point.url][index], payload.data)

                        const var1 = {
                            key: "tableData",
                            value: fullData
                        }

                        context.commit("doMutate", [var1])

                        if(context.state.coolDownSaveTable === 0)
                        {
                            //update all table
                            context.dispatch("getTableData", {
                                table: context.getters.currentTable.name,
                                output: context.getters.currentTable.url,
                                nasil: true,
                                that: that,
                            })
                            context.commit("addCoolDown")
                        }
                    }
                    else {
                        that.$root.$emit("alert", "Entity edit error", 2)
                    }
                }, true)
        },
        deleteTableData: async function(context, payload) {
            const that = payload.that
            delete payload.that

            await nvgpost(Vue.prototype.$apiEndpoint+"/v1/table_data",
                "DELETE",
                payload,
                {}, (res) => {
                    if(res.status && res.status === 1) {
                        let currentTableData = Object.assign({}, context.state.tableData)
                        currentTableData[payload.point.url] = currentTableData[payload.point.url].filter(function (obj) {
                            return obj.id.toString() !== payload.entityId.toString()
                        })

                        const var1 = {
                            key: "tableData",
                            value: currentTableData
                        }

                        context.commit("doMutate", [var1])

                        that.$root.$emit("alert", "Entity deleted successfully!", 1)

                        //update all table
                        context.dispatch("getTableData", {
                            table: context.getters.currentTable.name,
                            output: context.getters.currentTable.url,
                            nasil: true,
                            that: that,
                        })
                    }
                    else {
                        that.$root.$emit("alert", "Entity delete error", 2)
                    }
                }, true)
        },
        cloneTableData: async function(context, payload) {
            payload.cloned = true
            await context.dispatch("createTableData", payload)
        },
        createTableData: async function(context, payload) {
            const that = payload.that
            delete payload.that

            await nvgpost(Vue.prototype.$apiEndpoint+"/v1/table_data",
                "POST",
                payload,
                {}, (res) => {
                    if(res.data) {
                        let currentTableData = Object.assign({}, context.state.tableData)
                        currentTableData[payload.point.url].push(res.data)

                        const var1 = {
                            key: "tableData",
                            value: currentTableData
                        }

                        context.commit("doMutate", [var1])
                        that.$root.$emit("alert", "New Entity created successfully!", 1)

                        //update all table
                        context.dispatch("getTableData", {
                            table: context.getters.currentTable.name,
                            output: context.getters.currentTable.url,
                            nasil: true,
                            that: that,
                        })
                    }
                    else {
                        if(res.error) {
                            const verboseColumn = context.getters.currentTable.actionData.entity.fields
                                .filter(e => e.name === res.error[1])[0].verbose
                            switch (res.error[0]) {
                                case "unique":
                                    that.$root.$emit("alert", verboseColumn + " should be unique", 2)
                                    break;
                                case "required":
                                    that.$root.$emit("alert", verboseColumn + " is required", 2)
                                    break;
                            }
                        }
                        else {
                            that.$root.$emit("alert", "Entity create error", 2)
                        }
                    }
                }, true)
        },
        getImages: async function(context, payload) {
            const that = payload.that
            delete payload.that

            await nvgpost(Vue.prototype.$apiEndpoint+"/v1/images",
                "GET",
                {},
                {}, (res) => {
                    if(res.data) {
                        context.commit("setImages", res.data)
                    }
                    else {
                        that.$root.$emit("alert", "Images Get error", 2)
                    }
                }, true)
        },
        getChartsDashboard: async function(context, payload) {
            const that = payload.that
            delete payload.that

            await nvgpost(Vue.prototype.$apiEndpoint+"/v1/weboacharts",
                "POST",
                {filter: payload.filterDate},
                {}, (res)=>{
                    if(res.data) {
                        context.commit("doMutate", [{
                            key: "chartsDashboard",
                            value: res.data
                        }])
                    }
                    else {
                        that.$root.$emit("alert", "Charts get error", 2)
                    }
                }, true)
        },
    }
})